<template>
  <PageContent>
    <div class="pb-5">
      <b-button @click.prevent="createActionItemModal()" type="is-primary" rounded>
        Create Action Item
      </b-button>
    </div>
    <div class="level pl-2">
      <div class="level-left level-item title">{{ $t('actionItemList.title') }}</div>
      <div class="level-right">
        <b-field tabindex="0" aria-label="action items status filter">
          <b-radio v-model="statusFilter" name="all" native-value="all" aria-label="All">
            All
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="notdone"
            native-value="notdone"
            aria-label="Not Done"
          >
            Not Done
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="inprogress"
            native-value="inprogress"
            aria-label="In Progress"
          >
            In Progress
          </b-radio>
          <b-radio v-model="statusFilter" name="done" native-value="done" aria-label="Done">
            Done
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="cancelled"
            native-value="cancelled"
            aria-label="Cancelled"
          >
            Cancelled
          </b-radio>
        </b-field>
      </div>
    </div>
    <div>
      <ModernTable
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
      />
      <Modal :toggle.sync="toggle">
        <ActionItemModal />

        <template v-slot:header>Create Action Item</template>
      </Modal>
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import ActionItemModal from './components/ActionItemModal'
import Modal from '@/components/Modal'
import { mapState, mapGetters } from 'vuex'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'ActionItemList',
  components: {
    PageContent,
    ModernTable,
    ActionItemModal,
    Modal
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,

  methods,

  async mounted() {
    await this.reload()
  },

  watch: {
    statusFilter: 'reload',
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  i18n: {
    messages: {
      en: {
        actionItemList: {
          title: 'Action Items'
        }
      }
    }
  }
}
</script>

<style></style>
