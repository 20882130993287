<template>
  <PageContentLayoutOnly role="region">
    <div class="level">
      <div class="level-left level-item title">{{ $t('boardMeetings.title') }}</div>
    </div>
    <LinksMenu
      aria-label="Board Meetings Tabs"
      :tabs="tabs"
      prefix="boardMeetings."
      :style="{
        paddingLeft: 0
      }"
    >
      <section :style="{ overflowX: 'auto', flexGrow: 2 }">
        <SlideTransition :name="transitionName">
          <keep-alive>
            <router-view :key="$route.name" />
          </keep-alive>
        </SlideTransition>
      </section>
    </LinksMenu>
  </PageContentLayoutOnly>
</template>

<script>
//
//
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
//
import SlideTransition from '@/components/transitions/Slide.vue'
import LinksMenu from '@/components/menus/Links'
//
import { data } from './parts/data'
//
export default {
  components: {
    PageContentLayoutOnly,
    LinksMenu,
    SlideTransition
  },

  data,

  i18n: {
    messages: {
      en: {
        boardMeetings: {
          title: 'Board Meeting Detail'
        }
      }
    }
  }
}
</script>
