import { actionItemStore } from '@/services/BoardActionItems/store'
import { notifyError, notifyMessage } from '@/services/notify'
import { parseDate } from '@/utilities/Date/parse'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    await this.loadActionItemStatus()

    await this.loadActionItemPriority()

    await this.loadSourceBoardPackages()

    this.formData.assignedTo = this.authUser.name

    this.loading = false
  },

  async loadSourceBoardPackages() {
    const dateFormat = 'MM/DD/YYYY hh:mm a'

    await actionItemStore
      .dispatch('getBoardPackagesList', {
        hoaID: this.hoaId
      })
      .then(({ list }) => {
        if (list) {
          this.sourceBoardPackagesList = list.map(m => ({
            label: `${m.type} Session ${
              m.meetingDate ? '-' + parseDate(m.meetingDate, dateFormat) : ''
            }`,
            value: m.boardPackageID
          }))
        }
      })
  },

  async loadActionItemStatus() {
    await actionItemStore.dispatch('getActionItemStatusList').then(({ list }) => {
      if (list) {
        this.actionItemStatusList = list.map(m => ({
          label: m.name,
          value: m.actionItemStatusID
        }))
      }
    })
  },

  async loadActionItemPriority() {
    await actionItemStore.dispatch('getActionItemPriorityList').then(({ list }) => {
      if (list) {
        this.actionItemPriorityList = list.map(m => ({
          label: m.name,
          value: m.actionItemPriorityID
        }))
      }
    })
  },

  open() {
    this.formData = {
      actionItemStatusID: 0,
      actionItemPriorityID: 0,
      sourceBoardPackageID: 0,
      completedDate: null,
      completeByDate: null,
      completedBy: '',
      assignedTo: '',
      sourceBoardPackage: '',
      title: '',
      description: ''
    }
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.toggle = !this.toggle))

    // auto-close modal
    $('#clickclose').click()
  },

  getPayload() {
    const payload = {
      sourceBoardPackageID: this.formData.sourceBoardPackageID,
      actionItemStatusID: this.formData.actionItemStatusID,
      actionItemPriorityID: this.formData.actionItemPriorityID,
      title: this.formData.title,
      description: this.formData.description,
      assignedToAspNetUserID: this.authUser.aspNetUserID,
      completedBy: this.formData.completedBy,
      completedDate: this.formData.completedDate,
      completeByDate: this.formData.completeByDate,
      hoaID: this.hoaId
    }

    return payload
  },

  async onFormSubmit() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem creating this Action Item.')
      return
    }
    this.loading = true

    await actionItemStore.dispatch('addActionItem', {
      payload: this.getPayload(),
      done: async () => {
        notifyMessage('The action item has been successfully created.')
        this.closeModal()
      }
    })
  }
}
